import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

class Contact extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="Contact Us" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <h2>Contact Us</h2>
            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/submitted">
              <label>
                Name
                <input type="text" name="name" id="name" />
              </label>
              <label>
                Email
                <input type="email" name="_replyto" id="email" />
              </label>
              <label>
                Subject
                <input type="text" name="subject" id="subject" />
              </label>
              <label>
                Message
                <textarea name="message" id="message" rows="5" />
              </label>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <button type="submit" className="special">Send</button>&nbsp;
              <input type="reset" value="Clear"/>
            </form>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Contact
